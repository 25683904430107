import React, { useContext, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { InputGroup, FormControl } from 'react-bootstrap';
import FileUpload from './FileUpload';
import FileContext from '../FileContext';
import axios from 'axios';

const ChatInput = ({ addMessage , setIsLoading, isLoading, iaRole, allRequiredFilesPresent, handleTriggerloadFileRequirements}) => {
    const [message, setMessage] = useState(''); 
    const { files } = useContext(FileContext);    

    const handleMessageChange = (event) => {
        setMessage(event.target.value);
    };

    const handleSendMessage = async () => {        
        if (isLoading) return;

        addMessage({ sender: localStorage.getItem('userName'), text: message, id: Date.now() });
        setIsLoading(true);

        // Get the current hostname
        const hostname = window.location.hostname;
        const port = window.location.port ? `:${window.location.port}` : '';
        const baseUrl = process.env.REACT_APP_CONVERSATION_SERVICE_API_BASE_URL || `${window.location.protocol}//${hostname}${port}/api/v1/`;

        const timestamp = Date.now();
        const date = new Date(timestamp);

        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');

        const formattedTime = `${hours}:${minutes}:${seconds}`;

        if(iaRole == "Workstream"){
            await handleTriggerloadFileRequirements();
        }
        if(iaRole == "Workstream" && !allRequiredFilesPresent){
            addMessage({ sender: `AI-CFM (${formattedTime}) `, text: "Sorry, I can't generate Workstreams at the moment because some required files are missing.\nPlease make sure all necessary files are uploaded before proceeding.", showSaveButton: false, dataToSave: false , id: Date.now()});
            setIsLoading(false);
        }
        else{
            const token = localStorage.getItem('token');
            axios.post(baseUrl + 'getLLMResponse', {            
                question: message,
                mode: iaRole,
                user_id: localStorage.getItem('userID')
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then(response => {
                console.log("response");
                console.log(response);
                addMessage({ sender: `AI-CFM (${formattedTime}) `, text: response.data.message, showSaveButton: response.data.showSaveButton, dataToSave: response.data.dataToSave , id: Date.now()});
                setIsLoading(false);          
            })
            .catch(error => {
                setIsLoading(false);
                console.error('Error:', error);
            });
    
            setMessage('');
        }
    };    

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSendMessage();
        }
    };

    return (
        <div style={{ position: 'fixed', bottom: 35, left: files.length > 0 ? '240px' : '0', right: '10px', width: files.length > 0 ? 'calc(100% - 260px)' : 'calc(100% - 20px)', marginLeft: '10px' }}>
            <InputGroup className="mb-3">
                <InputGroup.Text style={{ cursor: isLoading ? 'not-allowed' : 'pointer' }}>
                    <FileUpload disabled={isLoading} />
                </InputGroup.Text>
                <FormControl
                    placeholder="Type a message"
                    aria-label="Type a message"
                    value={message}
                    onChange={handleMessageChange}
                    onKeyPress={handleKeyPress}
                    disabled={isLoading}
                />
                <InputGroup.Text onClick={!isLoading ? handleSendMessage : undefined} style={{ cursor: isLoading ? 'not-allowed' : 'pointer' }}>
                    <FontAwesomeIcon icon={faPaperPlane} />
                </InputGroup.Text>
            </InputGroup>
        </div>
    );
    
};

export default ChatInput;