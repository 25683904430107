import React from 'react';
import './styles/Login.css';
import logo from'../images/SmartStreamTempLogos.svg';
import image from'../images/4_1.svg';

const Login = ({ handleLogin }) => {
    return (
        <div className="login-container">
          <div className="top-bar">
            <img src={logo} alt="Logo" />
          </div>
          <div className="content">
            <div className="text">
                Artificial{'\n'} 
                Intelligence Driven{'\n'}
                Continuous Flow{'\n'}
                Methodology
            </div>
            <div className="description">
              Leverage AI in your Software Lifecycle.
            </div>
            <div className="image-container" style={{ backgroundImage: `url(${image})` }}></div>
            <button className="button" onClick={handleLogin}>
                Login
            </button>
            <div className="copyright">
                © 2024 Calavista Software. All rights reserved.
            </div>
          </div>
        </div>
      );
};

export default Login;