import React, { useState, useEffect } from 'react';

const Modal = ({
  showModal,
  setShowModal,
  handleLogout,
  baseApiUrl
}) => {
  const [jiraUrl, setJiraUrl] = useState('');
  const [jiraUser, setJiraUser] = useState('');
  const [jiraToken, setJiraToken] = useState('');
  const [projectKey, setProjectKey] = useState('');

  useEffect(() => {
    setJiraUrl(localStorage.getItem('jiraUrl') || '');
    setJiraUser(localStorage.getItem('jiraUser') || '');
    setJiraToken(localStorage.getItem('jiraToken') || '');
    setProjectKey(localStorage.getItem('projectKey') || '');
  }, []);

  const handleAcceptClick = () => {
    localStorage.setItem('jiraUrl', jiraUrl);
    localStorage.setItem('jiraUser', jiraUser);
    localStorage.setItem('jiraToken', jiraToken);
    localStorage.setItem('projectKey', projectKey);
    setShowModal(false);
  };

  const handleCancelClick = () => {
    setShowModal(false);
  };

  const handleAddToSlackClick = () => {
    setShowModal(false);
    const cfmUserId = localStorage.getItem('userID')
    window.location.href = `${baseApiUrl}oauth2/link_slack/?cfm_user_id=${cfmUserId}`; 
  };

  return (
    <div className="modal" style={{ display: showModal ? 'flex' : 'none' }}>
      <div className="modal-content">
        <h2>Settings</h2>
        <label htmlFor="jiraUrl">JIRA URL:</label>
        <input type="text" id="jiraUrl" value={jiraUrl} onChange={(e) => setJiraUrl(e.target.value)} />
        <label htmlFor="jiraUser">JIRA User:</label>
        <input type="text" id="jiraUser" value={jiraUser} onChange={(e) => setJiraUser(e.target.value)} />
        <label htmlFor="jiraToken">JIRA Token:</label>
        <input type="text" id="jiraToken" value={jiraToken} onChange={(e) => setJiraToken(e.target.value)} />
        <label htmlFor="projectKey">Project Key:</label>
        <input type="text" id="projectKey" value={projectKey} onChange={(e) => setProjectKey(e.target.value)} />
        <div className="modal-buttons">
          <button onClick={handleAcceptClick}>Ok</button>
          <button onClick={handleCancelClick}>Cancel</button>
          <button onClick={handleAddToSlackClick}>Add to Slack</button>
          <button onClick={handleLogout}>Logout</button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
