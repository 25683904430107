import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSliders } from '@fortawesome/free-solid-svg-icons';
import './styles/ChatMode.css';

const ChatMode = ({ changeIARole, handleSettingsClick, isLoading }) => {
    const [selectedButton, setSelectedButton] = useState('Workstream');

    useEffect(() => {
        changeIARole(selectedButton);
    }, [selectedButton, changeIARole]);

    const handleButtonClick = (role) => {
        if (!isLoading && selectedButton !== role) {
            setSelectedButton(role);
            changeIARole(role);
        }
    };

    return (
        <div className="conversation-bar">
            <button
                className={`chat-mode-button ${selectedButton === 'Workstream' ? 'active' : ''}`}
                onClick={() => handleButtonClick('Workstream')}
                disabled={isLoading}
                style={{ cursor: isLoading ? 'not-allowed' : 'pointer' }}
            >
                Workstream
            </button>
            <button
                className={`chat-mode-button ${selectedButton === 'epics' ? 'active' : ''}`}
                onClick={() => handleButtonClick('epics')}
                disabled={isLoading}
                style={{ cursor: isLoading ? 'not-allowed' : 'pointer' }}
            >
                Epics & User Stories
            </button>
            <button
                className={`chat-mode-button ${selectedButton === 'Unknown' ? 'active' : ''}`}
                onClick={() => handleButtonClick('Unknown')}
                disabled={isLoading}
                style={{ cursor: isLoading ? 'not-allowed' : 'pointer' }}
            >
                Normal
            </button>
            <button
                className="settings-button"
                onClick={handleSettingsClick}
                disabled={isLoading}
                style={{ cursor: isLoading ? 'not-allowed' : 'pointer' }}
            >
                <FontAwesomeIcon icon={faSliders} />
            </button>
        </div>
    );
};

export default ChatMode;
