import React, { useState } from 'react';

const RequiredFileUploadStatus = ({ selectedFile, statusMessage, show }) => {
  const [isExpanded] = useState(true);

  return (
    <div style={{
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '30%',
      maxHeight: isExpanded ? '100%' : '50px',
      overflow: 'auto',
      display: show ? 'block' : 'none',
      zIndex: 1000
    }}>
      <div className="p-3 bg-white border rounded">
        {isExpanded && selectedFile && (
          <div>
            <p>{selectedFile.name}</p>
            <p>{statusMessage}...</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default RequiredFileUploadStatus;
