import React, { useState } from 'react';
import { ProgressBar } from 'react-bootstrap';


const FileUploadStatus = ({ selectedFiles, uploadProgress, show, handleClose }) => {
    const [isExpanded] = useState(true);


    return (
        <div style={{
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '30%',
    maxHeight: isExpanded ? '100%' : '50px',
    overflow: 'auto',
    display: show ? 'block' : 'none',
    zIndex: 1000
}}>
    <div className="p-3 bg-white border rounded">
        {isExpanded && [...selectedFiles].map((file, i) => (
            <div key={i}>
                <p>{file.name}</p>
                <ProgressBar now={uploadProgress[i] || 0} label={`${uploadProgress[i] || 0}%`} />
            </div>
        ))}
    </div>
</div>
    );
};

export default FileUploadStatus;