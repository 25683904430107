
import React from 'react';
import './styles/Spinner.css';

const Spinner = () => {
    return (
      <div className="spinner">
        <p></p>
      </div>
    );
  };

export default Spinner;