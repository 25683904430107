import React from 'react';
// import axios from 'axios'; // Will use to query microservices for their versions, if needed

const clusterName = () =>{
    // TODO: Query microservices for cluster
    // return process.env.REACT_APP_CLUSTER;
    return 'prod';
}

const envName = () =>{
    // TODO: Query microservices for environment
    // return process.env.REACT_APP_ENVIRONMENT;
    return 'Staging';
}

const Footer = () => {
    return (
        <div style={{
            position: 'fixed',
            bottom: 0,
            width: '100%',
            height: '30',
            overflow: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '2',
            boxSizing: 'border-box',
            fontSize: '0.8em',
            color: '#333'
        }}>
            <span>Calavista Software, Inc. &copy; 2024 - Platform Version: {envName()}-{process.env.REACT_APP_PLATFORM_VERSION}</span>
        </div>
    )
}

export default Footer;